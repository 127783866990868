import React from 'react'

import Android from '../assets/android.png'
import Kotlin from '../assets/kotlin.png'
import Firebase from '../assets/firebase.png'
import MongoDb from '../assets/mongodb.png'
import Compose from '../assets/logo.png'
import HTML from '../assets/html.png'
import CSS from '../assets/css.png'
import GitHub from '../assets/github.png'
import JetpackCompose from '../assets/jetpackcompose.png'

const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen bg-[#0a192f] text-gray-300'>
        {/* Container */}
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
          <div>
            <p className='text-4xl font-bold inline border-b-4 border-pink-600'>Skills</p>
            <p className='py-4'>// There are the technologies I've worked with</p>
          </div>

          <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
            <div className='shadow-md bg-[#0a333333] pt-8 border border-black rounded-2xl hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={Android} alt="Android icon" />
                <p className='my-4'>Android</p>
            </div>
            <div className='shadow-md bg-[#0a333333] pt-8 border border-black rounded-2xl hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={JetpackCompose} alt="Compose icon" />
                <p className='my-4'>Jetpack Compose</p>
            </div>
            <div className='shadow-md bg-[#0a333333] pt-8 border border-black rounded-2xl hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={Kotlin} alt="Kotlin icon" />
                <p className='my-4'>Kotlin</p>
            </div>
            <div className='shadow-md bg-[#0a333333] pt-8 border border-black rounded-2xl hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={HTML} alt="HTML icon" />
                <p className='my-4'>HTML</p>
            </div>
            <div className='shadow-md bg-[#0a333333] pt-8 border border-black rounded-2xl hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={CSS} alt="CSS icon" />
                <p className='my-4'>CSS</p>
            </div>
            <div className='shadow-md bg-[#0a333333] pt-8 border border-black rounded-2xl hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={Firebase} alt="Firebase icon" />
                <p className='my-4'>Firebase</p>
            </div>
            <div className='shadow-md bg-[#0a333333] pt-8 border border-black rounded-2xl hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={MongoDb} alt="Mongo icon" />
                <p className='my-4'>Mongo DB</p>
            </div>
            <div className='shadow-md bg-[#0a333333] pt-8 border border-black rounded-2xl hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={GitHub} alt="Github icon" />
                <p className='my-4'>Github</p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Skills