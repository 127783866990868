import React, {useState} from 'react';
import {FaBars, FaTimes, FaGithub, FaLinkedin, FaFacebook, FaLinkedinIn, FaGit} from 'react-icons/fa';
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import Logo from '../assets/logo.png'
import {Link} from 'react-scroll'

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => {
        setNav(!nav);
        document.body.classList.toggle("overflow-hidden");
      };

  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center sm:px-24 px-8 bg-[#0a192f] text-gray-300'>
        <div>
            {/* <h1 className='text-pink-600 font-bold text-4xl'>HC</h1> */}
            <img src={Logo} alt='logo' style={{width: '50px'}} />
        </div>

        {/* menu */}
        <ul className='hidden md:flex'>
            <Link to="home" smooth={true} duration={500}>
                <li className='hover:bg-pink-600 py-2 rounded-sm justify-between items-center'>
                    Home
                </li>
            </Link>
            <Link to="about" smooth={true} duration={500}>
                <li className='hover:bg-pink-600 py-2 rounded-sm justify-between items-center'>
                    About
                </li>
            </Link>
            <Link to="skills" smooth={true} duration={500}>
                <li className='hover:bg-pink-600 py-2 rounded-sm justify-between items-center'>
                    Skills
                </li>
            </Link>
            <Link to="work" smooth={true} duration={500}>
                <li className='hover:bg-pink-600 py-2 rounded-sm justify-between items-center'>
                    Work
                </li>
            </Link>
            <Link to="contact" smooth={true} duration={500}>
                <li className='hover:bg-pink-600 py-2 rounded-sm justify-between items-center'>
                    Contact
                </li>
            </Link>
        </ul>
    
        {/* Hamburger */}
        <div onClick={handleClick} className='md:hidden z-10'>
            {!nav ? <FaBars/> : <FaTimes/>}
        </div>

        {/* Mobile menu */}
        <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'}>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="home" smooth={true} duration={500}>
                    Home
                </Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="about" smooth={true} duration={500}>
                    About
                </Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
                    Skills
                </Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="work" smooth={true} duration={500}>
                    Work
                </Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
                    Contact
                </Link>
            </li>
        </ul>

        {/* Social icons */}
        <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
            <ul>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#0A66C2]'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                        target='_blank' href='https://www.linkedin.com/in/hitenchawda/'>
                            LinkedIn <FaLinkedin size={30} />
                    </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                        target='_blank' href='https://github.com/Hiten24'>
                            Github <FaGithub size={30} />
                    </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#E54033]'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                        target='_blank' href='mailto:hitenchawda24@gmail.com'>
                            Email <HiOutlineMail size={30} />
                    </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]'>
                    <a className='flex justify-between items-center w-full text-gray-300'
                        target='_blank' href='https://drive.google.com/file/d/1s_mNOkDWljFE1O4oiQ0t91AZxQqPvUZx/view?usp=sharing'>
                            Resume <BsFillPersonLinesFill size={30} />
                    </a>
                </li>
            </ul>
        </div>

    </div>
  )
}

export default Navbar