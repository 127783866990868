import React from 'react'
import WorkImg from '../assets/projects/workImg.jpeg'
import Journal from '../assets/projects/JournalAppjpg.jpg'
import Xpenzave from '../assets/projects/XpenzaveThumbnail.png'
import ComposeUI from '../assets/projects/ComposeUI.jpg'

const Work = () => {
  return (
    <div name='work' className='w-full md:h-screen text-gray-300 bg-[#0a192f]'>
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
          <div className='pb-8'>
            <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600'>Work</p>
            <p className='py-6'>// Check out some of my recent work</p>
          </div>

{/* Container */}
          <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
            {/* Grid Item */}
            <div style={{backgroundImage: `url(${Journal})`}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto project-div'>

              {/* Hover Effects */}
              <div className='text-center opacity-0 group-hover:opacity-100'>
                <span className='text-2xl font-bold text-white tracking-wider'>
                  Journal App
                </span>
                <div className='pt-8 text-center'>
                  <a target='_blank' href="https://play.google.com/store/apps/details?id=com.hcapps.journal&pcampaignid=web_share">
                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                  </a>
                  <a target='_blank' href="https://github.com/Hiten24/Journal.git">
                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                  </a>
                </div>
              </div>
            </div>

            {/* Grid Item */}
            <div style={{backgroundImage: `url(${Xpenzave})`}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto project-div'>

              {/* Hover Effects */}
              <div className='text-center opacity-0 group-hover:opacity-100'>
                <span className='text-2xl font-bold text-white tracking-wider'>
                  Xpenzave
                </span>
                <div className='pt-8 text-center'>
                  <a target='_blank' href="https://play.google.com/store/apps/details?id=com.hcapps.xpenzave&pcampaignid=web_share">
                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                  </a>
                  <a target='_blank' href="https://github.com/Hiten24/Xpenzave.git">
                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                  </a>
                </div>
              </div>
            </div>
      
            {/* Grid Item */}
            <div style={{backgroundImage: `url(${ComposeUI})`}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto project-div'>

              {/* Hover Effects */}
              <div className='text-center opacity-0 group-hover:opacity-100'>
                <span className='text-2xl font-bold text-white tracking-wider'>
                  Compose UI Templates
                </span>
                <div className='pt-8 text-center'>
                  {/* <a href="/">
                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                  </a> */}
                  <a target='_blank' href="https://github.com/Hiten24/Compose-Ui-Templates.git">
                    <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Work